import { render, staticRenderFns } from "./LocationPickerBody.vue?vue&type=template&id=bde9d826&lang=pug&"
import script from "./LocationPickerBody.vue?vue&type=script&lang=ts&"
export * from "./LocationPickerBody.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBarFieldLocation: require('/home/runner/work/gg_code/gg_code/nuxt/components/search-bar/SearchBarFieldLocation.vue').default,GDialogHeader: require('/home/runner/work/gg_code/gg_code/nuxt/components/GDialogHeader.vue').default,LocationPickerShortcut: require('/home/runner/work/gg_code/gg_code/nuxt/components/location-picker/LocationPickerShortcut.vue').default,LocationPickerPrediction: require('/home/runner/work/gg_code/gg_code/nuxt/components/location-picker/LocationPickerPrediction.vue').default})
